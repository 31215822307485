<template>
  <div class="protospelling">
    <div v-if="loading || !protospelling">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="protospelling.spelling" :pretitle="'Protospelling details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button @click="deleteItem" v-if="$store.getters.checkRole('legam.dict.protospelling.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.dict.protospelling.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button @click="deleteItem" v-if="!$store.getters.checkRole('legam.dict.protospelling.modify') && $store.getters.checkRole('legam.dict.protospelling.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Spelling</h6>
                  <b-form-input
                    id="spelling"
                    v-model="protospelling.spelling"
                    placeholder="Spelling"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="protospelling.spelling"></span>
                </b-col>
                <b-col>
                  <h6>Comment</h6>
                  <b-form-textarea
                    id="comment"
                    placeholder="Comment"
                    v-model="protospelling.comment"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="protospelling.comment"></span>
                </b-col>
              </b-row>
            </article>
          </b-col>
        </b-row>
        <hr class="mt-4">
        <div class="accordion mt-4" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-1 class="text-left">
                Lemmas <small>({{ protospelling.lemmas.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-lemma>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-lemma" title="Add lemma" ok-only ok-variant="secondary" size="lg">
                  <LemmaAutocomplete @select="addLemma" />
                </b-modal>
                <LemmaTable
                  :showTotal="false"
                  :lemmas="protospelling.lemmas"
                  @delete="deleteLemma"
                  :editMode="editMode"
                  v-if="protospelling.lemmas.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-2 class="text-left">
                Occurrences <small>({{ protospelling.occurrences.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-occurrence>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-occurrence" title="Add occurrence" ok-only ok-variant="secondary" size="lg">
                  <OccurrenceAutocomplete @select="addOccurrence" />
                </b-modal>
                <OccurrenceTable
                  :showTotal="false"
                  :occurrences="protospelling.occurrences"
                  @delete="deleteOccurrence"
                  :editMode="editMode"
                  v-if="protospelling.occurrences.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-3 class="text-left">
                References <small>({{ protospelling.references.length }} entries)</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-reference>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-reference" title="Add reference" ok-only ok-variant="secondary" size="lg">
                  <ReferenceAutocomplete @select="addReference" />
                </b-modal>
                <ReferenceTable
                  :showTotal="false"
                  :references="protospelling.references"
                  @delete="deleteReference"
                  :editMode="editMode"
                  v-if="protospelling.references.length"
                />
                <span v-else>No data</span>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable objectType="protospelling" :objectId="protospellingId" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
  h6 {
    margin-bottom: 2px;
    margin-top: 1rem;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import LemmaTable from '@/components/Lemma/Table.vue'
  import OccurrenceTable from '@/components/OccurrenceTable.vue'
  import ReferenceTable from '@/components/ReferenceTable.vue'
  import LemmaAutocomplete from '@/components/Lemma/Autocomplete.vue'
  import OccurrenceAutocomplete from '@/components/OccurrenceAutocomplete.vue'
  import ReferenceAutocomplete from '@/components/ReferenceAutocomplete.vue'
  import config from '@/config.js'

  export default {
    name: 'ProtospellingDetails',
    data(){
      return {
        config: config,
        protospellingId: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0
      }
    },
    components: {
      PageTitle,
      ChangeLogsTable,
      LemmaTable,
      LemmaAutocomplete,
      OccurrenceTable,
      OccurrenceAutocomplete,
      ReferenceTable,
      ReferenceAutocomplete
    },
    computed: {
      ...mapState({
        protospelling: state => state.protospelling.protospelling
      })
    },
    watch: {
      'protospelling'() {
        this.loading = false
      }
    },
    mounted(){
      this.$store.dispatch('loadProtospelling', {
        protospellingId: this.$route.params.id
      })
    },
    methods: {
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadProtospelling', {
          protospellingId: this.protospellingId
        })
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveProtospelling', this.protospelling).then(() => {
          this.$store.dispatch('loadProtospelling', {
            protospellingId: this.protospellingId
          })
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      addLemma(lemma){
        this.$bvModal.hide('modal-add-lemma')
        this.protospelling.lemmas.push(lemma)
      },
      deleteLemma(lemmaId){
        this.protospelling.lemmas = this.protospelling.lemmas.filter(lemma => lemma.id != lemmaId)
      },
      addOccurrence(occurrence){
        this.$bvModal.hide('modal-add-occurrence')
        this.protospelling.occurrences.push(occurrence)
      },
      deleteOccurrence(occurrenceId){
        this.protospelling.occurrences = this.protospelling.occurrences.filter(occurrence => occurrence.id != occurrenceId)
      },
      addReference(reference){
        this.$bvModal.hide('modal-add-reference')
        this.protospelling.references.push(reference)
      },
      deleteReference(referenceId){
        this.protospelling.references = this.protospelling.references.filter(reference => reference.id != referenceId)
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteProtospelling', this.protospelling).then(() => {
              router.push('/protospellings')
            })
          }
        })
      }
    }
  }
</script>
